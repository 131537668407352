export const STYLES = {
  dark: {
    bg: 'rgb(47, 62, 70)',
    bgAlt: 'rgb(53, 79, 82)',
    primary: 'rgb(132, 169, 140)',
    primaryAlt: 'rgba(82, 121, 111, 0.4)',
    white: 'rgb(218, 218, 218)',
    light: 'rgba(202, 210, 197, 0.6)',
    bgImage:
      'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAZlBMVEUAAAD///8AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABpshDWAAAAInRSTlMAAAECAwQGBwgJCgwNDg8REhMUFRYXGBkbHB0eHyAjJCUnTiXhiAAAAHFJREFUeNplzMUBhUAUQ9Hg7u7Qf5HA5M1XsstZXBh/gxGF0HMdKChbX/++JOT7RHHHLSPY1T54/BUIsMpb+DUoWVZ+gVu6MenxBe58dKojwF5TsCzgqR7LAvbC3i1jQEhn9mGXS2YqcBPI/Dom/O0BF9UMBvRJ/iE6AAAAAElFTkSuQmCC")'
  },
  light: {
    bg: 'rgb(255, 255, 240)',
    bgAlt: 'rgba(202, 210, 197, 0.6)',
    primary: 'rgba(82, 121, 111, 1)',
    primaryAlt: 'rgb(132, 169, 140)',
    white: 'rgb(47, 62, 70)',
    light: 'rgb(53, 79, 82)',
    bgImage:
      'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAJFBMVEUAAAD///8AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAW+S7RAAAADHRSTlMAAAECAwQFBgcICQo5OyerAAAAXUlEQVR42mXMxQEDMAxDUTN1/3kLisu6/Rcg/hmxGe1UAJG6XXFgWk+PAySmFB0EIAh64SE9j34CSZYXfYD2JUufgP8SPy/gP4EApPEfBOD96Ie0A9TpTNMAP/uDG2yfApwmo2RoAAAAAElFTkSuQmCC")'
  }
};
